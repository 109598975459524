.token {
    border-radius: 8px;
    display: flex;
    width: calc(100% - 24px);
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: content-box;
    max-width: 480px;
    box-shadow: 0 0 8px 2px #b5ceff63;
    margin: 12px 0;
    padding: 12px;
    background: #0f002e;
}

.token-content {
    align-items: center;
    align-self: center;
    border-radius: 8px;
    display: flex;
    width: 100%;
}

.token-details {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.token-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 4px;
    border-radius: 8px;
    text-align: center;
    background: rgba(245, 245, 245, 0.144);
    margin-right: 24px;
    width: 108px;
}

.token-icon:active {
    background:rgba(245, 245, 245, 0.077);
}

.token-icon-empty {
    background: rgba(245, 245, 245, 0.288);
}

.token-icon span {
    font-size: 14px;
    text-align: center;
}

.token-icon img {
    margin: 0 8px 0px 0;
    border-radius: 45px;
}

.token-icon:hover {
    box-shadow: 0 0 2px 1px lightgrey;
    cursor: pointer;
}

.token-amount {
    text-align: right;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 48px;
    min-width: 120px;
    max-width: 360px;
}

.token-amount span {
    font-size: 14px;
    color: grey;
    margin: 2px;
}

.token-input {
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    text-align: right;
    font-weight: 500;
    padding: 12px;
    background: rgba(245, 245, 245, 0.288);
    color: rgba(245, 245, 245, 0.692);
}

.token-input:disabled {
    color: lightgrey;
}

.token-warning {
    margin: 8px 0;
}

.token-warning span {
    font-size: 12px;
}

.token-warning a {
    color: inherit;
}

.token-slider {
    height: 80px;
    display: flex;
    flex-direction: column;
}

.slider-button {
    outline: none;
    background: none;
    margin: 8px 4px 0px;
    border: none;
    width: 50px;
    background: #b5ceffb2;
    cursor: pointer;
    border-radius: 4px;
}

.slider-button:hover {
    box-shadow: 0 0 4px 2px #b5ceff5d;
}

.slider-button:active {
    background: #b5ceff9a;
}

.token-slippage {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    cursor: pointer;
    width: max-content;
    text-align: center;
}

.token-slippage-input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding: 0 25%;
}

.token-slippage-input-button {
    width: 64px;
    padding: 8px 0;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    background: rgba(245, 245, 245, 0.144);
    color: white;
    box-sizing: border-box;
}

.token-slippage-input-button:active {
    background: rgba(245, 245, 245, 0.077);
}

.token-slippage-input {
    border-radius: 8px;
    padding: 8px;
    background:rgba(245, 245, 245, 0.144);
    outline: none;
    border: 1px solid transparent;
    box-sizing: border-box;
    color: lightgrey;
    min-width: 96px;
}

.token-slippage-input:focus {
    border: 1px solid #b5ceff9a;
    color: white;
}

.token-slippage-confirm {
    margin: 18px auto auto auto;
}

.token-slippage-confirm-button {
    width: 180px;
    padding: 8px 0;
    border-radius: 8px;
    border: none;
    outline: none;
    background:rgba(245, 245, 245, 0.144);
    color: white;
    cursor: pointer;
}

.token-slippage-confirm-button:hover {
    box-shadow: 0 0 4px 2px rgba(245, 245, 245, 0.077);
}

.token-slippage-confirm-button:active {
    background:rgba(245, 245, 245, 0.077);
}