.eth-deposit {
    border-radius: 8px;
    display: flex;
    width: calc(100% - 24px);
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: content-box;
    max-width: 480px;
    box-shadow: 0 0 8px 2px #b5ceff63;
    margin: 12px 0;
    padding: 12px;
    background: #0f002e;
    color: white;
}

.eth-deposit_h1 {
    margin: 24px 0 0 0;
    padding: 0;
    font-weight: 400;
}

.deposit-content {
    align-items: center;
    align-self: center;
    border-radius: 8px;
    display: flex;
    width: 100%;
}

.deposit-details {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.deposit-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    background: rgba(245, 245, 245, 0.144);
    margin-right: 24px;
    width: 100px;
}

.deposit-icon span {
    font-size: 14px;
    text-align: center;
}

.deposit-icon img {
    margin: 0 0 4px 0;
    border-radius: 45px;
}

.deposit-amount {
    text-align: right;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 48px;
    min-width: 120px;
}

.deposit-amount span {
    font-size: 12px;
    margin: 2px;
    color: grey;
}

.deposit-input {
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    text-align: right;
    font-weight: 500;
    padding: 12px;
    background: rgba(245, 245, 245, 0.288);
    color: lightgrey;
}

.deposit-label {
    align-items: center;
    display: flex;
}

.token-deposit-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 4px;
    border-radius: 8px;
    text-align: center;
    background: rgba(245, 245, 245, 0.144);
    margin-right: 24px;
    width: 108px;
}

.token-deposit-icon:active {
    background:rgba(245, 245, 245, 0.432);
}

.token-deposit-icon-empty {
    background: rgba(245, 245, 245, 0.077);
}

.token-deposit-icon span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.token-deposit-icon img {
    margin: 0 8px 0px 0;
    border-radius: 45px;
}

.token-deposit-icon:hover {
    box-shadow: 0 0 2px 1px lightgrey;
    cursor: pointer;
}

.token-approve-button {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 8px 0;
    border: none;
    outline: none;
    background: rgba(245, 245, 245, 0.577);
    cursor: pointer;
}

.token-approve-button:active {
    background:rgba(245, 245, 245, 0.288)
}

.token-approve-button:disabled {
    cursor: not-allowed;
}