.search {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 24px);
    padding: 12px;
}

.search-input {
    outline: none;
    border: 1px solid #b5ceff9c;
    color: #b5ceff;
    background: none;
    padding: 14px 8px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-radius: 8px;
}

.search-input:focus {
    border: 1px solid #b5ceff;
}

.token-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    margin-top: 24px;
}

.token-list-item {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: 4px 0;
    align-items: center;
    border-radius: 8px;
    width: 100%;
}

.token-list-item > * {
    margin: 8px 0;
}

.token-list-item:hover {
    background: #b5ceff3f;
    cursor: pointer;
}

.token-list-item-address {
    position: relative;
    bottom: 4px;
    font-size: 10px;
    color: inherit;
}

.token-list-item-brand {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 8px;
}

.token-list-item-brand img {
    margin-right: 8px;
}

.token-list-item-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}

.token-list-item-link {
    padding: 8px;
}

.token-list-item-link a {
    text-decoration: none;
    color: inherit;
}

.token-list-item-link:hover {
    color: #ff8d01;
}