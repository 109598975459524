.app {
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  top: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 520px;
  box-shadow: 0 0 8px 2px rgba(50,50,50,0.8);
  padding: 12px;
  border-radius: 8px;
  box-sizing: border-box;
}

h3 {
  margin: 4px 0;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}