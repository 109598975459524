.slide {
    margin: 0;
  }
  
  .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 6px;
      background: #b5ceff63;
      outline: none;
      opacity: 0.7;
      -webkit-transition: .5s;
      transition: opacity .2s;
      border-radius: 60px;
    }
    
    .slider:hover {
      opacity: 1;
    }
  
    .slide-percent {
      width: 100%;
      text-align: center;
      margin-top: 6px;
      font-size: 14px;
    }
    
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 14px;
      height: 14px;
      border-radius: 45px;
      background: #ff8d01;
      cursor: pointer;
    }
    
    .slider::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 14px;
      height: 14px;
      border-radius: 45px;
      background: #ff8d01;
      cursor: pointer;
    }