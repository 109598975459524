.wallet {
    position: absolute;
    top: 24px;
    right: 24px;
    height: 64px;
    display: flex;
    align-items: center;
    background: #0f002e;
}

.wallet-content {
    border: 1px solid lightgrey;
    border-radius: 8px;
    align-self: center;
    padding: 12px;
    width: 120px;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.wallet-connect {
    cursor: pointer;
}

.wallet-connect:hover {
    color: darkgrey;
}

.wallet-connect-indicator {
    height: 14px;
    width: 14px;
    border-radius: 45px;
    align-self: center;
    background: red;
}

.indicator-on {
    background: green;
}

.floating-wallet-ct {
    display: flex;
    width: 180px;
    border-radius: 8px;
    overflow: hidden;
    position: fixed;
    top: 32px;
    right: 24px;
    margin: 0;
    flex-direction: column;
    box-shadow: 0 0 8px 2px #b5ceff3d;
    z-index: 999;
}

.floating-wallet-drop {
    width: 42px;
    height: 42px;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #b5ceff;
    cursor: pointer;
}

.floating-wallet {
    background: #0f002e;
    color: whitesmoke;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 4px;
}

.floating-wallet-top {
    display: flex;
    justify-content: flex-end;
    margin-left: 24px;
    margin-right: 6px;
    letter-spacing: 0.05em;
    font-size: 12.5px;
    font-weight: 500;
    height: 42px;
    align-items: center;
}

.floating-wallet-top:hover {
    color: rgb(184, 184, 184);
}

.floating-wallet-top a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.floating-wallet-indicator {
    width: 12px;
    height: 12px;
    border-radius: 45px;
    margin: 0 4px 0 6px;
}

.floating-wallet-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: right;
    font-size: 12px;
    align-items: center;
    align-content: center;
    margin: 4px 0;
}

.floating-wallet-link:hover {
    color: silver;
    cursor: pointer;
}

.floating-wallet-balance {
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.floating-wallet-balance span {
    font-size: 14.5px;
    font-weight: 600;
}

.floating-wallet-balance img {
    width: 24px;
    margin: 0 4px 0 8px;
}

.floating-wallet-balance-usd {
    height: 24px;
    font-size: 11.25px;
    font-weight: 600;
    color: #b5ceff;
    text-align: right;
    margin-right: 8px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.floating-wallet-link {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 8px;
}

.wallet-list {
    display: flex;
    flex-direction: column;
}

.wallet-list-item {
    padding: 12px;
    border: 1px solid #b5ceff;
    margin: 12px 0;
    border-radius: 8px;
    cursor: pointer;
}

.wallet-list-item:hover {
    box-shadow: 0 0 8px 2px #b5ceff62
}

.wallet-list-item:active {
    background: #0f002e;
}