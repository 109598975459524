.controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 12px 0;
}
.control-buttons {
    display: flex;
    justify-content: flex-end;
    width: 80px;
}

.control-buttons button {
    display: flex;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 45px;
    outline: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
}

.control-button-remove {
    background: lightgrey;
}

.control-button-add {
    background: #ff8d01;
    margin-left: 12px;
}

.control-button-add:active {
    background: #ff8d01b4;
}

.control-button-remove:active {
    background: rgba(250, 250, 250, 0.3);
}

.submit-buttons {
    display: flex;
    width: 100%;
}

.submit-button {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 36px;
    align-items: center;
    background: none;
    outline: none;
    border: none;
    background: #b5ceffd8;
    color: white;
    border-radius: 6px;
    cursor: pointer;
}

.submit-button:hover {
    box-shadow: 0 0 4px 2px #b5ceff81;
}

.submit-button:disabled {
    background: grey;
    cursor: not-allowed;
}

.submit-button:active {
    background: #b5ceff88;
}

.control-buttons-container {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 24px);
    align-items: center;
    margin: 36px 0 0 0;
}

.confirm-list {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    text-align: left;
    flex-grow: 1;
    margin: 24px 12px;
}

.confirm-line-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #b5ceff5b;
    font-size: 14px;
}

.confirm-item-symbol {
    font-weight: 600;
}

.pending-tx {
    position: fixed;
    right: 24px;
    bottom: 24px;
    padding: 12px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: calc(220px - 24px);
    box-shadow: 0 0 8px 2px #b5ceff88;
    border-radius: 8px;
}

.pending-tx-status {
    align-self: center;
    display: flex;
    align-items: center;
}

.pending-tx-close {
    cursor: pointer;
}

.pending-tx-hash-a {
    color: whitesmoke;
}