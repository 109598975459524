.navigation {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: #0f002e;
    box-shadow: 0 0 12px 0px #b5ceffab;
    box-sizing: border-box;
}

.nav-item {
    color: white;
    text-align: center;
    text-decoration: none;
    text-align: center;
    padding: 12px 0;
    flex-grow: 1;
    font-size: 14px;
}

.nav-item-active {
    background: #ff8d01;
    color: white;
}