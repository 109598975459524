.modal {
    z-index: 999;
    background: #0f002e;
    position: fixed;
    height: 360px;
    overflow: hidden;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    border-radius: 14px;
    box-shadow: 0 0 0 100vmax #b5ceff27;
}

.modal-shell {
    display: flex;
    width: calc(100% - 48px);
    height: calc(100% - 72px);
    flex-direction: column;
    justify-content: flex-start;
    padding: 36px 24px;
}

.modal-close {
    position: fixed;
    top: 12px;
    right: 12px;
    border-radius: 8px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-close:hover {
    box-shadow: 0 0 4px 2px #b5ceffa1;
    cursor: pointer;
}

.modal-header {
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: white;
}

.modal-token-list {
    overflow-y: scroll;
    overscroll-behavior: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.modal-token-list-item {
    display: flex;
    justify-content: space-between;
}

.modal-token-list-item:hover {
    background: #b5ceff83;
    cursor: pointer;
}

.modal-token-list-item-brand {
    display: flex;
    flex-grow: 11;
    align-items: center;
    padding: 12px 24px;
}

.modal-token-list-item-brand img {
    margin-right: 12px;
}

.modal-token-search {
    display: flex;
    flex-direction: column;
}

.modal-token-search-span {
    font-size: 14px;
    font-weight: 600;
    margin: 12px 0;
}

.modal-token-search-button {
    padding: 12px;
    background: #b5ceffc2;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 4px;
}

.modal-token-search-button:hover {
    background: blue;
}

.disconnect-modal {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
}

.disconnect-modal-action {
    width: 45%;
    padding: 12px 0;
    background: #b5ceffbe;
    border: none;
    outline: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.disconnect-modal-action-cancel {
    width: 45%;
    padding: 12px 0;
    background: #9e9e9e80;
    border: none;
    outline: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.disconnect-modal-action:hover {
    box-shadow: 0 0 4px 1px #b5ceff73;
}

.disconnect-modal-action:active {
    background: #b5ceff3b;
}

.disconnect-modal-action-cancel:hover {
    box-shadow: 0 0 4px 1px #9e9e9e80;
}

.disconnect-modal-action-cancel:active {
    background: rgba(120, 121, 122, 0.644);
}